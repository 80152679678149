import userConstants from '../constants/userConstants';
import { Dispatch } from 'redux'

type updateUserTypes = {
  name: string,
  phone: string,
  email: string,
  currentPassword: string,
  newPassword: string
}
export function updateUser({ name = '', phone = '', email = '', currentPassword = '', newPassword = '' }: updateUserTypes) {
  return (dispatch: Dispatch, getState: any) => {
    const id = getState().user.pid
    return dispatch({
      type: userConstants.TYPES.UPDATE_USER_INFO,
      meta: {
        type: 'api',
        endpoint: `/users/${id}`,
        method: 'PUT',
        body: {
          name,
          phone,
          email,
          currentPassword,
          newPassword
        }
      }
    })
  }
}

export function deleteUser() {
  return (dispatch: Dispatch, getState: any) => {
    return dispatch({
      type: userConstants.TYPES.DELETE_USER,
      meta: {
        type: 'api',
        endpoint: `/users/${getState().user.pid}`,
        method: 'DELETE'
      }
    })
  }
}

export const removeAllUserData = () => ({
  type: userConstants.TYPES.DELETE_USER_DATA
})
