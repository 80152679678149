import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom'

// Views
import LoginView from './LoginView'
import RegisterView from './RegisterView'
import ResetView from './ResetView'
import RecoverView from './RecoverView'
import HomeView from './HomeView/index'
import CreateLockView from './CreateLockView'
import EditLockView from './EditLockView'
import SettingsView from './SettingsView'
import AccessBar from '../components/AccessBar'

const createRoutes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='' element={
        <AccessBar />
      }>
        <Route path='/locks/create' element={<CreateLockView />} />
        <Route path='/locks/:pid/edit' element={<EditLockView />} />
        <Route path='/settings' element={<SettingsView />} />
        <Route path='/' element={<HomeView />} />
      </Route>

      <Route path='/login' element={<LoginView />} />
      <Route path='/register' element={<RegisterView />} />
      <Route path='/reset' element={<ResetView />} />
      <Route path='/recover' element={<RecoverView />} />
      <Route path='*' element={<Navigate to="/" />} />
    </>
  )
);

export default createRoutes;
