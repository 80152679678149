import { REHYDRATE } from 'redux-persist/constants'
import { } from 'redux-persist'
import UserSchema, { userSchemaType } from '../schemas/userSchema'
import authConstants from '../constants/authConstants'
import userConstants from '../constants/userConstants'
import cloneDeep from "lodash/cloneDeep"

const initialState = cloneDeep(UserSchema)

export default function userState(state = cloneDeep(initialState), action: any) {
  let newState = cloneDeep(state)

  switch (action.type) {

    case REHYDRATE:      
      return action.payload.user != null
        ? action.payload.user as userSchemaType
        : state

    case authConstants.TYPES.REGISTER_LOCAL:
    case authConstants.TYPES.LOGIN_LOCAL:
    case authConstants.TYPES.RECOVER_LOCAL:
      if (action.meta.pending || action.error) {
        return state
      }
      newState.pid = action.payload.user.pid
      newState.name = action.payload.user.name
      newState.email = action.payload.user.email
      newState.phone = action.payload.user.phone
      return newState

    case userConstants.TYPES.UPDATE_USER_INFO:
      if (action.meta.pending || action.error || !action.payload) {
        return state
      }

      newState.pid = action.payload.pid
      newState.name = action.payload.name
      newState.email = action.payload.email
      newState.phone = action.payload.phone
      return newState

    case userConstants.TYPES.DELETE_USER_DATA:
    case authConstants.TYPES.LOG_OUT:
    case authConstants.TYPES.ACCESS_TOKEN_EXPIRED:
      return initialState;

    default:
      return state
  }
}
