import React from 'react'

const CcLogo = (props: any) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="96" height="32" viewBox="0 0 96 32" {...props}>
    <title>double-C-sort</title>
    <path d="M51.14 16.212c0 2.182 1.013 4.18 2.271 5.82 2.068 2.695 4.802 4.426 4.802 4.426 3.333 1.928 6.736 3.233 10.057 4.082 4.151 1.060 8.185 1.41 11.819 1.37 8.919-0.098 15.44-2.54 15.44-2.54l0.543-5.122h-1.579c0 0-3.263 5.23-13.762 5.23 0 0-10.718 0.134-17.072-4.559-2.677-1.976-4.497-4.809-4.497-8.867v-0.101c0-4.060 1.82-6.893 4.497-8.868 6.354-4.692 17.072-4.559 17.072-4.559 10.498 0 13.762 5.231 13.762 5.231h1.579l-0.543-5.122c0 0-6.52-2.441-15.44-2.54-3.634-0.040-7.667 0.309-11.819 1.369-3.322 0.85-6.724 2.154-10.057 4.082 0 0-2.734 1.73-4.802 4.425-1.258 1.641-2.271 3.638-2.271 5.82v0.423z" />
    <path d="M44.724 15.74c0-2.168-1.007-4.153-2.256-5.782-2.055-2.677-4.771-4.397-4.771-4.397-3.312-1.916-6.692-3.212-9.992-4.056-4.124-1.053-8.132-1.401-11.742-1.361-8.861 0.097-15.339 2.524-15.339 2.524l-0.54 5.088h1.569c0 0 3.242-5.196 13.672-5.196 0 0 10.648-0.133 16.96 4.529 2.66 1.964 4.467 4.778 4.467 8.81v0.1c0 4.033-1.808 6.848-4.467 8.811-6.313 4.662-16.96 4.529-16.96 4.529-10.43 0-13.672-5.197-13.672-5.197h-1.569l0.54 5.089c0 0 6.478 2.425 15.339 2.523 3.61 0.040 7.618-0.307 11.742-1.36 3.3-0.844 6.681-2.14 9.992-4.055 0 0 2.716-1.719 4.771-4.397 1.25-1.63 2.256-3.615 2.256-5.782v-0.421z" />
  </svg>
)

export default CcLogo
