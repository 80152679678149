import React, { useEffect, useState } from 'react'
import _zxcvbn from 'zxcvbn'

// Components
import { Label, Popup, List, SemanticCOLORS } from 'semantic-ui-react'

type passwordStrenght = {
  password: string
}

function PasswordStrength({ password }: passwordStrenght) {

  const [crackTime, setCrackTime] = useState<string | number>("");
  const [warning, setWarning] = useState("")
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [popupHasContent, setPopupHasContent] = useState(false)
  const [labelColor, setLabelColor] = useState<SemanticCOLORS | undefined>(undefined)

  useEffect(() => {
    let zxcvbn = _zxcvbn(password)

    let _suggestions = zxcvbn.feedback.suggestions
    let _warning = zxcvbn.feedback.warning

    setCrackTime(zxcvbn.crack_times_display.offline_slow_hashing_1e4_per_second)
    setWarning(_warning)
    setSuggestions(_suggestions)
    setPopupHasContent(_suggestions.length > 0 || _warning.length > 0)
    setLabelColor(getLabelColor(zxcvbn.score))
  }, [password])

  const getLabelColor = (score: number) => {
    switch (score) {
      case 0:
        return 'red'
      case 1:
        return 'orange'
      case 2:
        return 'yellow'
      case 3:
        return 'olive'
      case 4:
        return 'green'
      default:
        return 'grey'
    }
  }


  const generateList = () => {
    return (
      <>
        {warning.length > 0 ? (
          <List.Item>
            <List.Content>
              <List.Header>Warnings</List.Header>
              <List.Description>
                <List>
                  <List.Item>{warning}</List.Item>
                </List>
              </List.Description>
            </List.Content>
          </List.Item>
        ) : (
          ''
        )}
        <List.Item>
          <List.Content>
            <List.Header>Suggestions</List.Header>
            <List.Description>
              <List bulleted={suggestions.length > 1}>
                {suggestions.length > 0
                  ? suggestions.map((suggestion) => <List.Item content={suggestion} key={`PasswordSuggestion:${suggestion}`} />)
                  : <>No suggestions</>
                }
              </List>
            </List.Description>
          </List.Content>
        </List.Item>
      </>
    )
  }


  return (
    <Popup
      basic
      position="top center"
      size="small"
      disabled={!popupHasContent}
      trigger={
        <Label as={popupHasContent ? 'a' : 'div'} className="password-strength" basic pointing color={labelColor}>
          Time to hack: <strong>{crackTime}</strong>
        </Label>
      }
    >
      <List>
        {generateList()}
      </List>
    </Popup>
  )
}

export default PasswordStrength
