import React from 'react'
import { useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

// Redux store
import { RootReducerState } from './store/reducers'

// Components
import routes from './routes/index'
import { RouterProvider } from 'react-router-dom'

// Styles

function App() {
  const isLoading = useSelector((state: RootReducerState) => !state.ui.isHydrated)

  return isLoading
    ? <Loader size="huge" active />
    : <RouterProvider router={routes} />
}

export default App
