import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { getSingleUseCode, clearSingleUseCode } from '../../../store/actions/lockActions'
import { RootReducerState } from '../../../store/reducers'

// Components
import { Modal, Message, Button, Form } from 'semantic-ui-react'
import { DateTime } from 'luxon'

// Styles

interface CodeCardProps {
  lockPid: string | undefined,
  lockName: string | undefined,
  username: string | undefined
}

function CodeCard({ lockPid, lockName, username }: CodeCardProps) {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false)
  const [canShare] = useState(!!navigator.share)
  const [canShareErrorMessage, setCanShareErrorMessage] = useState("")

  const isGetting = useSelector((state: RootReducerState) => state.ui.editLock.getSingleUseCode.isGetting)
  const getSuccess = useSelector((state: RootReducerState) => state.ui.editLock.getSingleUseCode.success)
  const getErrorMessage = useSelector((state: RootReducerState) => state.ui.editLock.getSingleUseCode.error?.message)
  const code = useSelector((state: RootReducerState) => state.ui.editLock.getSingleUseCode.fields.singleUseCode)
  const codesLeft = useSelector((state: RootReducerState) => state.ui.editLock.getSingleUseCode.fields.codesLeft)

  const handleGetCode = () => {
    dispatch(
      getSingleUseCode({
        lockPid: lockPid
      })
    )
  }

  const handleOpen = () => setModalOpen(true)
  const handleClose = () => setModalOpen(false)

  useEffect(() => {
    if (code && !modalOpen) {
      dispatch(clearSingleUseCode())
    }
  }, [modalOpen, code, dispatch])

  useEffect(() => {
    if (!canShare) {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Firefox") && !userAgent.includes("Mobile")) {
        setCanShareErrorMessage("To share the code, you need to enable sharing in Firefox. To do this, type 'about:config' in the address bar, search for 'dom.webshare.enabled', and set it to 'true'.")
      } else {
        setCanShareErrorMessage("Your browser does not support sharing.")
      }

      return
    }
  }, [canShare])

  const dateformat = (date: string | null) => {
    if (!date) return
    const split = date.split('-');

    const year = split[0]
    const month = split[1].padStart(2, '0')
    const day = split[2].padStart(2, '0')

    return `${day}/${month}/${year}`
  }

  const shareCode = async () => {
    if (canShare) {
      await navigator.share({
        title: `Access granted to lock ${lockName}`,
        text: `${username} has granted you access to lock: \n${lockName}. \n` +
          `You can unluck it with the code: \n${code} \n` +
          `The code can be used before ${dateformat(DateTime.local().toISODate())} (23:59:59) after that it's expired.`
      })
    }
  }

  if (!canShare) {
    return (
      <Modal
        trigger={
          <Button primary basic onClick={handleOpen} loading={isGetting}>
            Share day code
          </Button>
        }
        open={modalOpen}
        onClose={handleClose}
      >
        <Modal.Header>Send day code</Modal.Header>
        <Modal.Content>
          <Message error header="Error" content={canShareErrorMessage} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <Modal
      trigger={
        <Button primary basic onClick={handleOpen} loading={isGetting}>
          Share single use code
        </Button>
      }
      open={modalOpen}
      onClose={handleClose}
    >
      <Modal.Header>Share single use code</Modal.Header>
      <Modal.Content>
        <Form error={getErrorMessage != null} success={getSuccess} loading={isGetting}>
          <Button onClick={handleGetCode} primary>
            Get code
          </Button>

          {code && <Message success header={`Code: ${code}`} content={
            <p>
              The code can be used before {dateformat(DateTime.local().toISODate())} (23:59:59) after that it's expired.
              <br />
              You have {codesLeft} codes left.
            </p>
          } />}

          <Message error header="Error" content={typeof getErrorMessage === "string" ? getErrorMessage : null} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" onClick={shareCode} loading={isGetting} disabled={!code}>
          Share code
        </Button>
        <Button basic color="red" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CodeCard
