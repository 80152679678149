import React from 'react'

import { NavLink, Link } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import CcLogo from '../../CcLogo'

const MobileMenu = ({ userEmail, handleLogout }: { userEmail: string, handleLogout: () => void }) => {
  return (
    <>
      <Menu.Item className="access-bar-spacer-mobile" />
      <Menu.Item as={Link} to="/" className="access-bar-logo-mobile">
        <CcLogo className="access-bar-logo" />
      </Menu.Item>
      <Menu.Menu position="right">
        <Dropdown item icon="bars" closeOnBlur fluid simple>
          <Dropdown.Menu>
            <Dropdown.Item disabled>
              Signed in as <strong>{userEmail}</strong>
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/">
              Home
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Lock</Dropdown.Header>
            <Dropdown.Item as={NavLink} to="/">
              View Locks
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/locks/create">
              Create Lock
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Settings</Dropdown.Header>
            <Dropdown.Item as={NavLink} to="/settings">
              Go to settings
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </>
  )
}

export default MobileMenu
