import rootReducer from './reducers'
import { compose, applyMiddleware, createStore } from 'redux'
import { persistStore, autoRehydrate } from "redux-persist"

// Shemas
import AuthSchema from './schemas/authSchema'
import UiSchema from './schemas/uiSchema'
import UserSchema from './schemas/userSchema'

// Middleware
import thunk from 'redux-thunk'
import apiMiddleware from './middleware/api-middleware'


export default function configureStore() {
    const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__ || null

    const devToolsActive = devTools != null && process.env.NODE_ENV !== 'production'

    const middleware = [thunk, apiMiddleware]

    const createReduxStore =
        devToolsActive
            ? compose(
                applyMiddleware(...middleware),
                devTools({
                    serialize: {
                        refs: [AuthSchema, UiSchema, UserSchema]
                    },
                    trace: true
                }),
                autoRehydrate()
            )
            : compose(
                applyMiddleware(...middleware),
                autoRehydrate()
            )

    const store = createReduxStore(createStore)(rootReducer)
    persistStore(store, {
        // @ts-ignore
        records: [AuthSchema, UserSchema],
        whitelist: ['auth', 'user']
    })

    return store
}
