export const TYPES = {
  TYPES: {
    ACCESS_TOKEN_EXPIRED: 'auth/ACCESS_TOKEN_EXPIRED',
    REGISTER_LOCAL: 'auth/REGISTER_LOCAL',
    LOGIN_LOCAL: 'auth/LOGIN_LOCAL',
    RECOVER_LOCAL: 'auth/RECOVER_LOCAL',
    RESET_LOCAL: 'auth/RESET_LOCAL',
    LOG_OUT: 'auth/LOG_OUT'
  }
}

export default TYPES
