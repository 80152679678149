import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unstable_useBlocker as useBlocker, useParams } from 'react-router-dom'
import routes from '../../../routes/index'
import isEqual from "lodash/isEqual"

// Components
import { Button, Container, Form, Icon, Label, Message, Header, Modal, Loader } from 'semantic-ui-react'
import SendDayCode from './SendDayCode'
import SendPeriodCode from './SendPeriodCode'
import SendSingleUseCode from './SendSingleUseCode'

// Redux store
import { deleteLock, getLock, updateLock, clearCurrentLock, removeFromLock } from '../../../store/actions/lockActions'
import { updateEditLockFormFields, addEditLockViewer, removeEditLockViewer } from '../../../store/actions/uiActions'

// Styles
import './EditLockView.scss'

import { RootReducerState } from '../../../store/reducers'

function EditLockView() {
  const dispatch = useDispatch()

  const { pid: urlPid } = useParams()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [hasChange, setHasChange] = useState(false)
  const [lockerErrorFields, setLockerErrorFields] = useState<[] | { field: string, message: string }[]>([])
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false)

  const currentLock = useSelector((state: RootReducerState) => state.locks.currentLock)
  const isLoading = useSelector((state: RootReducerState) => state.ui.editLock?.isLoading)

  // Edit lock fields
  const seed = useSelector((state: RootReducerState) => state.ui.editLock?.fields.seed)
  const code = useSelector((state: RootReducerState) => state.ui.editLock?.fields.code)
  const codeLength = useSelector((state: RootReducerState) => state.ui.editLock?.fields.codeLength)
  const description = useSelector((state: RootReducerState) => state.ui.editLock?.fields.description)
  const location = useSelector((state: RootReducerState) => state.ui.editLock?.fields.location)
  const managers = useSelector((state: RootReducerState) => state.ui.editLock?.fields.managers)
  const name = useSelector((state: RootReducerState) => state.ui.editLock?.fields.name)
  const viewer = useSelector((state: RootReducerState) => state.ui.editLock?.fields.viewer)
  const viewers = useSelector((state: RootReducerState) => state.ui.editLock?.fields.viewers)
  const username = useSelector((state: RootReducerState) => state.auth.accessToken?.user.name)

  // Errors
  const lockError = useSelector((state: RootReducerState) => state.ui.editLock?.error) // eslint-disable-line
  const lockErrorMessage = useSelector((state: RootReducerState) => state.ui.editLock?.error?.message)
  const lockIsLoading = useSelector((state: RootReducerState) => state.ui.editLock?.isLoading)

  const blocker = useBlocker(hasChange)

  useEffect(() => {
    if (blocker.location) {
      setUnsavedChangesModalOpen(true)
    }
  }, [blocker]) // eslint-disable-line

  const handleLockUpdate = () => {
    if (hasChange) {
      dispatch(
        updateLock({
          lockPid: urlPid,
          lockType: '',
          seed: seed,
          codeLength: codeLength,
          name: name,
          description: description,
          location: location,
          viewers: viewers
        })
      )
    }
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, valueAsNumber } = e.target

    let updateValue = (() => {
      switch (name) {
        case 'codeLength':
          return valueAsNumber;
        default:
          return value;
      }
    })();

    dispatch(
      updateEditLockFormFields({
        [name]: updateValue
      })
    )
  }

  const handleDeleteLock = () => {
    handleConfirmClose()
    dispatch(deleteLock({ lockPid: urlPid }))
  }

  const handleRemoveMe = () => {
    handleConfirmClose()
    dispatch(removeFromLock({ lockPid: urlPid }))
  }

  const handleConfirmOpen = () => setConfirmOpen(true)
  const handleConfirmClose = () => setConfirmOpen(false)

  const handleAddViewer = () => {
    let isManager = managers.find(_viewer => _viewer === viewer)
    if (viewer != null && viewer.length > 5 && !isManager) {
      dispatch(addEditLockViewer(viewer))
    } else if (isManager) {
      dispatch(
        updateEditLockFormFields({
          viewer: ''
        })
      )
    }
  }

  const handleRemoveViewer = (email: string) => () => {
    dispatch(removeEditLockViewer(email))
  }

  const handleCancel = () => {
    routes.navigate('/')
  }

  const generateViewerLabels = () => {
    if (viewers.length < 1) {
      return <p>No viewers</p>
    }

    return viewers.map(viewer =>
      <Label key={`VIEWER_LABEL:${viewer}`}>
        <Icon name="mail" />
        {viewer}
        <Icon name="delete" onClick={handleRemoveViewer(viewer)} />
      </Label>
    )
  }

  const generateManagerLabels = () => {
    return managers?.map(manager =>
      <Label key={`MANAGER_LABEL:${manager}`}>
        <Icon name="mail" />
        {manager}
      </Label>
    )
  }

  useEffect(() => {
    const errorMessage = lockErrorMessage
    if (lockErrorMessage !== null &&
      typeof lockErrorMessage === "object" &&
      lockErrorMessage.length > 0) {
      let fieldErrors = errorMessage == null || typeof errorMessage === 'string'
        ? []
        : errorMessage.map((errorObject) => {
          let message = errorObject.message.replace(/^("([a-zA-Z]+)+" )/, "")
          message = message[0].toUpperCase() + message.slice(1)

          return {
            field: errorObject.path[0],
            message: message
          }
        })

      setLockerErrorFields(fieldErrors)
    }
  }, [lockErrorMessage])


  const isAdmin = () => {
    const seed = currentLock?.seed
    return seed != null && String(seed).length > 0
  }

  useEffect(() => {
    let changed =
      currentLock?.seed !== seed ||
      currentLock?.codeLength !== codeLength ||
      currentLock?.name !== name ||
      currentLock?.description !== description ||
      !isEqual(currentLock?.location, location) ||
      !isEqual(currentLock?.viewers, viewers) ||
      !isEqual(currentLock?.managers, managers)

    setHasChange(changed)
  }, [codeLength, description, location, managers.length, name, seed, viewers.length, isLoading]) // eslint-disable-line

  useEffect(() => {
    dispatch(getLock({ lockPid: urlPid }))

    return () => {
      dispatch(clearCurrentLock())
    }
  }, []) // eslint-disable-line

  // TODO: Separate into own components, so that it also can have types with different layout.
  if (lockIsLoading)
    return <Loader size="huge" active />

  if (isAdmin())
    return (
      <Container as="main" id="EditLockView" style={{ paddingTop: '4em' }}>
        <Form loading={lockIsLoading} error={lockErrorMessage != null}>
          <Form.Input fluid label="Code" name="code" value={code} disabled />
          <Form.Input
            fluid
            label="Seed"
            name="seed"
            type="number"
            placeholder="Seed e.g. 1234"
            value={seed || ""}
            onChange={handleUpdateField}
            error={
              lockerErrorFields.filter(error => error.field === "seed").length > 0
                ? lockerErrorFields.filter(error => error.field === "seed")[0].message
                : false
            }
          />
          <Form.Input
            fluid
            label="Code length"
            name="codeLength"
            type="number"
            min={4}
            max={8}
            value={codeLength}
            onChange={handleUpdateField}
            error={
              lockerErrorFields.filter(error => error.field === "codeLength").length > 0
                ? lockerErrorFields.filter(error => error.field === "codeLength")[0].message
                : false
            }
          />
          <Form.Input
            fluid
            label="Name"
            type="text"
            name="name"
            value={name || ""}
            onChange={handleUpdateField}
            min="4"
            max="8"
          // error={this.lockErrorFields.includes('name')}
          />
          <Form.Input
            fluid
            label="Description"
            name="description"
            type="text"
            value={description || ""}
            onChange={handleUpdateField}
          // error={this.lockErrorFields.includes('description')}
          />
          <Form.Input
            fluid
            label="Location"
            name="location"
            disabled
            type="text"
            value="Not available at the moment"
            onChange={handleUpdateField}
          // error={this.lockErrorFields.includes('location')}
          />

          <div className="manager-labels field">
            <label>Managers</label>
            {generateManagerLabels()}
          </div>

          <div className="lock-viewers">
            <div className="viewer-labels field">
              <label>Viewers</label>
              {generateViewerLabels()}
            </div>
            <Form.Input fluid label="Add viewer" name="viewer" type="email" value={viewer} placeholder="E-mail address" onChange={handleUpdateField} />
            <Button fluid primary onClick={handleAddViewer}>
              Add viewer
            </Button>
          </div>

          <div className="send-code-panel field">
            <label>Share codes</label>
            <SendDayCode lockPid={urlPid} lockName={name} username={username} />
            <SendPeriodCode lockPid={urlPid} lockName={name} username={username} />
            <SendSingleUseCode lockPid={urlPid} lockName={name} username={username} />
          </div>

          <Button.Group fluid className="edit-lock-button-group" widths="2">
            <Button color="green" type="submit" onClick={handleLockUpdate} disabled={!hasChange}>
              Update Lock
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Button.Group>
          <Modal
            trigger={
              <Button fluid className="delete-lock-button" size="large" color="red" onClick={handleConfirmOpen}>
                Delete lock
              </Button>
            }
            open={confirmOpen}
            onClose={handleConfirmClose}
            basic
            size="small"
          >
            <Header icon="exclamation triangle" content="Confirm lock deletion" />
            <Modal.Content>
              <p>This will delete this lock and all managers and viewers will lose access</p>
              <h3>
                <strong>This is a permanent action and can't be undone</strong>
              </h3>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={handleConfirmClose} inverted>
                <Icon name="cancel" /> Cancel
              </Button>
              <Button color="red" onClick={handleDeleteLock}>
                <Icon name="trash alternate" /> Confirm delete
              </Button>
            </Modal.Actions>
          </Modal>
          <Message error header="Error" content={typeof lockErrorMessage === "string" ? lockErrorMessage : null} />
        </Form>

        <Modal
          open={unsavedChangesModalOpen}
          onClose={() => setUnsavedChangesModalOpen(false)}
          basic
          size="small"
        >
          <Header icon="exclamation triangle" content="You have unsaved changes" />
          <Modal.Content>
            <h3>
              <strong>Updated info isn't saved, are you sure you want to leave?</strong>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setUnsavedChangesModalOpen(false)} inverted>
              <Icon name="cancel" /> Stay
            </Button>
            <Button color="red" onClick={blocker?.proceed}>
              <Icon name="trash alternate" /> Leave
            </Button>
          </Modal.Actions>
        </Modal>
      </Container>
    )


  return (
    <Container as="main" id="EditLockView" style={{ paddingTop: '4em' }}>
      <Form loading={lockIsLoading} error={lockErrorMessage != null}>
        <Form.Input disabled fluid type='text' name="code" label="Code" value={code} />
        <Form.Input disabled fluid type="number" name="codeLength" label="Code length" value={codeLength} />
        <Form.Input disabled fluid type="text" name="name" label="Name" value={name} min="4" max="8" />
        <Form.Input disabled fluid type="text" name="description" label="Description" value={description} />
        <Form.Input disabled fluid type="text" name="location" label="Location" value="Not available at the moment" />

        <div className="manager-labels field">
          <label>Managers</label>
          {generateManagerLabels()}
        </div>

        <Modal
          trigger={
            <Button fluid className="delete-lock-button" size="large" color="red" onClick={handleConfirmOpen}>
              Remove me
            </Button>
          }
          open={confirmOpen}
          onClose={handleConfirmClose}
          basic
          size="small"
        >
          <Header icon="exclamation triangle" content="Confirm lock deletion" />
          <Modal.Content>
            <p>This will remove you from this lock and all access will be lost</p>
            <h3>
              <strong>This is a permanent action and can't be undone</strong>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleConfirmClose} inverted>
              <Icon name="cancel" /> Cancel
            </Button>
            <Button color="red" onClick={handleRemoveMe}>
              <Icon name="trash alternate" /> Confirm removal
            </Button>
          </Modal.Actions>
        </Modal>

        {/* <Message error header="Error" content={lockErrorMessage} /> */}
      </Form>
    </Container>
  )
}

export default EditLockView
