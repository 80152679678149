import React, { useState } from 'react'

import { Form, Button, Icon, Input, SemanticICONS } from 'semantic-ui-react'

type passwordFormField = {
  icon: SemanticICONS,
  children?: any,
  [props: string]: any
}

function PasswordFormField(props: passwordFormField) {
  const { icon, children, ...restProps } = props
  
  const [showPassword, setShowPassword] = useState(false)
  
  const handleToggleInputType = () => {
    setShowPassword(prev => !prev);
  }

  return (
    <Form.Field>
      <Input action {...restProps}>
        <Icon name={icon} />
        <input type={showPassword ? 'text' : 'password'} />
        <Button as="div" icon onClick={handleToggleInputType}>
          <Icon name={showPassword ? 'eye slash' : 'eye'} />
        </Button>
      </Input>

      {children}
    </Form.Field>
  )
}

export default PasswordFormField
