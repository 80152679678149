export const TYPES = {
  TYPES: {
    UPDATE_REGISTER_FORM_FIELDS: 'ui/UPDATE_REGISTER_FORM_FIELDS',
    CLEAR_REGISTER_FORM: 'ui/CLEAR_REGISTER_FORM',
    UPDATE_LOGIN_FORM_FIELDS: 'ui/UPDATE_LOGIN_FORM_FIELDS',
    UPDATE_CREATE_LOCK_FORM_FIELDS: 'ui/UPDATE_CREATE_LOCK_FORM_FIELDS',
    ADD_CREATE_LOCK_VIEWER: 'ui/ADD_CREATE_LOCK_VIEWER',
    REMOVE_CREATE_LOCK_VIEWER: 'ui/REMOVE_CREATE_LOCK_VIEWER',
    UPDATE_EDIT_LOCK_FORM_FIELDS: 'ui/UPDATE_EDIT_LOCK_FORM_FIELDS',
    ADD_EDIT_LOCK_VIEWER: 'ui/ADD_EDIT_LOCK_VIEWER',
    REMOVE_EDIT_LOCK_VIEWER: 'ui/REMOVE_EDIT_LOCK_VIEWER',
    UPDATE_RESET_FORM_FIELDS: 'ui/UPDATE_RESET_FORM_FIELDS',
    CLEAR_RESET_FORM_FIELDS: 'ui/CLEAR_RESET_FORM_FIELDS',
    RESET_FORM_ERROR: 'ui/RESET_FORM_ERROR',
    UPDATE_RECOVER_FORM_FIELDS: 'ui/UPDATE_RECOVER_FORM_FIELDS',
    RECOVER_FORM_ERROR: 'ui/RECOVER_FORM_ERROR',
    UPDATE_SETTINGS_FORM_FIELDS: 'ui/UPDATE_SETTINGS_FORM_FIELDS',
    SETTINGS_FORM_ERROR: 'ui/SETTINGS_FORM_ERROR'
  }
}

export default TYPES
