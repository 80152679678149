import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Menu } from 'semantic-ui-react'

// Redux store
import { logOut } from '../../../store/actions/authActions'
import { RootReducerState } from '../../../store/reducers'

// Components
import MobileMenu from './MobileMenu'
import DesktopMenu from './DesktopMenu'

// Styles
import './AccessBar.scss'
import { Outlet, useNavigate } from 'react-router-dom'

function AccessBar() {
  const dispatch = useDispatch();
  let navigate = useNavigate()

  const accessToken = useSelector((state: RootReducerState) => state.auth.accessToken)
  const email = useSelector((state: RootReducerState) => state.user.email)

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const handleLogout = () => dispatch(logOut())

  const windowResize = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    return () => window.removeEventListener("resize", windowResize);
  });

  useEffect(() => {
    if (!accessToken) navigate("/login", { replace: true })
  }, [accessToken]) //eslint-disable-line

  return (
    <>
      <Menu id="AccessBar" fixed="top" vertical={false} inverted color="blue">
        {isDesktop
          ? <DesktopMenu handleLogout={handleLogout} userEmail={email} />
          : <MobileMenu handleLogout={handleLogout} userEmail={email} />
        }
      </Menu>
      <Outlet />
    </>
  )
}

export default AccessBar
