import React from 'react'
import { Link } from 'react-router-dom'

// Components
import { Card } from 'semantic-ui-react'

// Styles
import './CodeCard.scss'

type Props = {
  pid: string,
  name: string,
  code: string
}

function CodeCard({ pid, name, code }: Props) {
  return (
    <Card id="CodeCard" as={Link} to={`/locks/${pid}/edit`} style={{ minWidth: 0 }}>
      <Card.Content>
        <Card.Header textAlign="center" className="code-card-header">
          {code}
        </Card.Header>
        <Card.Description textAlign="center" className="code-card-description">
          {name || <>No name!</>}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default CodeCard
