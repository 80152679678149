import uiConstants from '../constants/uiConstants'

export const updateRegisterFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_REGISTER_FORM_FIELDS,
    payload: field
  }
}

export const clearRegisterForm = () => {
  return {
    type: uiConstants.TYPES.CLEAR_REGISTER_FORM
  }
}

export const updateLoginFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_LOGIN_FORM_FIELDS,
    payload: field
  }
}

export const updateResetFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_RESET_FORM_FIELDS,
    payload: field
  }
}

export const clearResetFormFields = () => {
  return {
    type: uiConstants.TYPES.CLEAR_RESET_FORM_FIELDS
  }
}

export const updateRecoverFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_RECOVER_FORM_FIELDS,
    payload: field
  }
}

export const updateSettingsFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_SETTINGS_FORM_FIELDS,
    payload: field
  }
}

export const updateCreateLockFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_CREATE_LOCK_FORM_FIELDS,
    payload: field
  }
}

export const addCreateLockViewer = (email: string) => {
  return {
    type: uiConstants.TYPES.ADD_CREATE_LOCK_VIEWER,
    payload: email
  }
}

export const removeCreateLockViewer = (email: string) => {
  return {
    type: uiConstants.TYPES.REMOVE_CREATE_LOCK_VIEWER,
    payload: email
  }
}

export const updateEditLockFormFields = (field: any) => {
  return {
    type: uiConstants.TYPES.UPDATE_EDIT_LOCK_FORM_FIELDS,
    payload: field
  }
}

export const addEditLockViewer = (email: string) => {
  return {
    type: uiConstants.TYPES.ADD_EDIT_LOCK_VIEWER,
    payload: email
  }
}

export const removeEditLockViewer = (email: string) => {
  return {
    type: uiConstants.TYPES.REMOVE_EDIT_LOCK_VIEWER,
    payload: email
  }
}
