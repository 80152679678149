import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'

// Redux store
import { clearDayCode, getDayCode } from '../../../store/actions/lockActions'

// Components
import { Modal, Message, Button, Form } from 'semantic-ui-react'

// Styles
import { RootReducerState } from '../../../store/reducers'

interface CodeCardProps {
  lockPid: string | undefined,
  lockName: string | undefined,
  username: string | undefined
}

function CodeCard({ lockPid, lockName, username }: CodeCardProps) {
  const dispatch = useDispatch()

  const [date, setDate] = useState(DateTime.local().toISODate())
  const [modalOpen, setModalOpen] = useState(false)
  const [canShare] = useState(!!navigator.share)
  const [canShareErrorMessage, setCanShareErrorMessage] = useState("")

  const isGetting = useSelector((state: RootReducerState) => state.ui.editLock.getDayCode.isGetting)
  const getSuccess = useSelector((state: RootReducerState) => state.ui.editLock.getDayCode.success)
  const getErrorMessage = useSelector((state: RootReducerState) => state.ui.editLock.getDayCode.error?.message)
  const code = useSelector((state: RootReducerState) => state.ui.editLock.getDayCode.fields.dayCode)

  const handleGetCode = () => {
    if (date) {
      dispatch(
        getDayCode({
          accessDay: date,
          lockPid: lockPid
        })
      )
    }
  }

  const handleOpen = () => setModalOpen(true)
  const handleClose = () => setModalOpen(false)

  useEffect(() => {
    if (code && !modalOpen) {
      dispatch(clearDayCode())
    }
  }, [modalOpen, code, dispatch])

  useEffect(() => {
    if (!canShare) {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Firefox") && !userAgent.includes("Mobile")) {
        setCanShareErrorMessage("To share the code, you need to enable sharing in Firefox. To do this, type 'about:config' in the address bar, search for 'dom.webshare.enabled', and set it to 'true'.")

      } else {
        setCanShareErrorMessage("Your browser does not support sharing.")
      }

      return
    }
  }, [canShare])

  const dateformat = (date: string | null) => {
    if (!date) return
    const split = date.split('-');

    const year = split[0]
    const month = split[1].padStart(2, '0')
    const day = split[2].padStart(2, '0')

    return `${day}/${month}/${year}`
  }

  const shareCode = async () => {
    if (canShare) {
      await navigator.share({
        title: `Access granted to lock ${lockName}`,
        text: `${username} has granted you access to lock: \n${lockName}. \n` +
          `You can unluck it with the code: \n${code} \n` +
          `The code is valid from ${dateformat(date)} until the end of the day (23:59:59)`
      })
    }
  }

  if (!canShare) {
    return (
      <Modal
        trigger={
          <Button primary basic onClick={handleOpen} loading={isGetting}>
            Share day code
          </Button>
        }
        open={modalOpen}
        onClose={handleClose}
      >
        <Modal.Header>Send day code</Modal.Header>
        <Modal.Content>
          <Message error header="Error" content={canShareErrorMessage} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" onClick={handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
  return (
    <Modal
      trigger={
        <Button primary basic onClick={handleOpen} loading={isGetting}>
          Share day code
        </Button>
      }
      open={modalOpen}
      onClose={handleClose}
    >
      <Modal.Header>Share day code</Modal.Header>
      <Modal.Content >
        <Form error={getErrorMessage != null} success={getSuccess} loading={isGetting}>
          <Form.Input
            label="Date"
            fluid
            icon="calendar"
            iconPosition="left"
            type="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min="1970-01-01"
          />
          <Button onClick={handleGetCode} primary>
            Get code
          </Button>

          {code && <Message success header={`Code: ${code}`} content={
            <p>Expires: {dateformat(date)} 23:59:59</p>
          } />}

          <Message error header="Error" content={typeof getErrorMessage === "string" ? getErrorMessage : null} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="green"
          onClick={shareCode}
          loading={isGetting}
          disabled={
            !date ||
            date.length < 6 ||
            DateTime.local(1970, 1, 1) > DateTime.fromISO(date) ||
            !code
          }
        >
          Share code
        </Button>
        <Button basic color="red" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default CodeCard
