// @ts-ignore
import { Axios } from 'axios'
import authConstants from '../constants/authConstants'

const api = new Axios({
  baseURL: process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BACKEND_URL_DEVELOPMENT
    : process.env.REACT_APP_BACKEND_URL_PRODUCTION
  ,
  headers: {
    common: {
      Accept: 'application/json'
    },
    post: {
      'Content-Type': 'application/json'
    },
    put: {
      'Content-Type': 'application/json'
    }
  }
})

/**
 * Action meta object
 *
 * @param {string} type Should be `api` to activate api middleware
 * @param {string} endpoint What path to use on the api
 * @param {string} method What http method to use
 * @param {object} body Used if method is
 * @param {navigate(response)} navigate If navigate function is present it will be called on a successful request with response inside
 */

const accessTokenExpired = () => ({ type: authConstants.TYPES.ACCESS_TOKEN_EXPIRED })

const handleAxiosError = ({ error, store, method, endpoint, type }: { error: any, store: any, method: any, endpoint: any, type: any }) => {
  if (process.env.NODE_ENV === 'development') {
    store.dispatch({
      type: `@@API/${method}-${endpoint}::FAILED`,
      payload: error,
      error: true
    })
  }

  type errorType = {
    type: any,
    payload: null | Error,
    error: boolean,
    meta: {
      pending: boolean
    }
  }

  const errorAction: errorType = {
    type: type,
    payload: null,
    error: true,
    meta: {
      pending: false
    }
  }

  if (error.response) {
    errorAction.payload = error.response.data
    if (error.response.status === 401) {
      store.dispatch(accessTokenExpired())
    }
  } else if (error.request) {
    if (!error.status) {
      errorAction.payload = new Error('No connection')
    } else {
      if (String(error.status) === '401') {
        store.dispatch(accessTokenExpired())
      }
      errorAction.payload = new Error(error.request)
    }
  } else {
    errorAction.payload = error
  }

  return store.dispatch(errorAction)
}

const apiMiddleware = (store: any) => (next: any) => (action: any) => {
  if (!action.meta || action.meta.type !== 'api') {
    return next(action)
  }
  const { endpoint = '', method = '', body = {}, navigate } = action.meta
  const type = action.type
  const state = store.getState()
  store.dispatch({ type: type, meta: { pending: true } })
  api.defaults.headers['Authorization'] = `Bearer ${state.auth.accessToken?.id}`

  if (process.env.NODE_ENV === 'development') {
    store.dispatch({
      type: `@@API/${method}-${endpoint}::STARTED`
    })
  }

  const responseAction = {
    type: type,
    payload: null,
    meta: {
      pending: false
    }
  }

  function handleResponse(response: any) {
    responseAction.payload = response.data
    store.dispatch(responseAction)
    if (navigate != null && typeof navigate === 'function') {
      navigate(response)
    }
  }

  switch (method) {
    case 'GET':
      return api
        .get(endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`)
        .then(handleResponse)
        .catch((error: any) => handleAxiosError({ error, store, method, endpoint, type }))

    case 'POST':
      return api
        .post(endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`, JSON.stringify(body))
        .then(handleResponse)
        .catch((error: any) => handleAxiosError({ error, store, method, endpoint, type }))

    case 'PUT':
      return api
        .put(endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`, JSON.stringify(body))
        .then(handleResponse)
        .catch((error: any) => handleAxiosError({ error, store, method, endpoint, type }))

    case 'DELETE':
      return api
        .delete(endpoint.charAt(0) === '/' ? endpoint : `/${endpoint}`)
        .then(handleResponse)
        .catch((error: any) => {
          handleAxiosError({ error, store, method, endpoint, type })
        })

    default:
      return store.dispatch({
        type: type,
        payload: new Error('Method not found'),
        error: true,
        meta: {
          pending: false
        }
      })
  }
}

export default apiMiddleware
