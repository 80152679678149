type errorSchema = {
  statusCode: string | number | null,
  error: string | null,
  message: string | null | propertyError[]
}

type propertyError = {
  message: string,
  path: string[],
  type: string,
  context: object
}

const errorState: errorSchema = {
  statusCode: null,
  error: null,
  message: null
}

export type uiSchemaType = {
  isHydrated: boolean,
  authentication: {
    login: {
      error: errorSchema,
      isLoading: boolean,
      fields: {
        email: string,
        password: string
      }
    },
    register: {
      error: errorSchema,
      isLoading: boolean,
      fields: {
        name: string,
        phone: string,
        email: string,
        password: string
      }
    },
    recover: {
      error: errorSchema,
      isLoading: boolean,
      fields: {
        password: string,
        passwordRepeat: string
      }
    },
    reset: {
      error: errorSchema,
      isLoading: boolean,
      fields: {
        email: string
      }
    }
  },
  locks: {
    isLoading: boolean,
    error: errorSchema
  },
  createLock: {
    isLoading: boolean,
    error: errorSchema,
    fields: {
      seed: string,
      codeLength: string,
      name: string,
      description: string,
      location: {},
      viewer: string,
      viewers: string[]
    }
  },
  editLock: {
    isLoading: boolean,
    error: errorSchema,
    fields: {
      seed: string,
      code: string,
      codeLength: string,
      name: string,
      description: string,
      location: {},
      viewer: string,
      viewers: string[],
      managers: string[]
    },
    // sendDayCode: {
    //   isSending: boolean,
    //   success: boolean,
    //   error: errorSchema
    // },
    // sendPeriodCode: {
    //   isSending: boolean,
    //   success: boolean,
    //   error: errorSchema
    // },
    // sendSingleUseCode: {
    //   isSending: boolean,
    //   success: boolean,
    //   error: errorSchema
    // }
    getDayCode: {
      isGetting: boolean
      success: boolean
      error: errorSchema
      fields: {
        dayCode: string
        expiryDate: string
      }
    }
    getPeriodCode: {
      isGetting: boolean
      success: boolean
      error: errorSchema
      fields: {
        periodCode: string
        validFrom: string
        validTo: string
      }
    }
    getSingleUseCode: {
      isGetting: boolean,
      success: boolean,
      error: errorSchema,
      fields: {
        singleUseCode: string,
        expiryDate: string
        codesLeft: string
      }
    }

  },
  settings: {
    isLoading: boolean,
    error: errorSchema,
    deleteSuccess: boolean,
    fields: {
      name: string,
      phone: string,
      email: string,
      currentPassword: string,
      newPassword: string
    }
  }
}

const uiSchema: uiSchemaType = {
  isHydrated: false,
  authentication: {
    login: {
      error: errorState,
      isLoading: false,
      fields: {
        email: '',
        password: ''
      }
    },
    register: {
      error: errorState,
      isLoading: false,
      fields: {
        name: '',
        phone: '',
        email: '',
        password: ''
      }
    },
    recover: {
      error: errorState,
      isLoading: false,
      fields: {
        password: '',
        passwordRepeat: ''
      }
    },
    reset: {
      error: errorState,
      isLoading: false,
      fields: {
        email: ''
      }
    }
  },
  locks: {
    isLoading: true,
    error: errorState
  },
  createLock: {
    isLoading: false,
    error: errorState,
    fields: {
      seed: '',
      codeLength: '4',
      name: '',
      description: '',
      location: {},
      viewer: '',
      viewers: []
    }
  },
  editLock: {
    isLoading: true,
    error: errorState,
    fields: {
      seed: '',
      code: '',
      codeLength: '4',
      name: '',
      description: '',
      location: {},
      viewer: '',
      viewers: [],
      managers: []
    },
    // sendDayCode: {
    //   isSending: false,
    //   success: false,
    //   error: errorState
    // },
    // sendPeriodCode: {
    //   isSending: false,
    //   success: false,
    //   error: errorState
    // },
    // sendSingleUseCode: {
    //   isSending: false,
    //   success: false,
    //   error: errorState
    // },
    getDayCode: {
      isGetting: false,
      success: false,
      error: errorState,
      fields: {
        dayCode: "",
        expiryDate: ""
      }
    },
    getPeriodCode: {
      isGetting: false,
      success: false,
      error: errorState,
      fields: {
        periodCode: "",
        validFrom: "",
        validTo: ""
      }
    },
    getSingleUseCode: {
      isGetting: false,
      success: false,
      error: errorState,
      fields: {
        singleUseCode: "",
        expiryDate: "",
        codesLeft: ""
      }
    }
  },
  settings: {
    isLoading: false,
    error: errorState,
    deleteSuccess: false,
    fields: {
      name: '',
      phone: '',
      email: '',
      currentPassword: '',
      newPassword: ''
    }
  }
}

export default uiSchema;

export { errorState };