import React, { useEffect, useState } from 'react'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from "../../../routes/index"

// Redux store
import { updateUser, deleteUser, removeAllUserData } from '../../../store/actions/userActions'
import { updateSettingsFormFields } from '../../../store/actions/uiActions'
import { RootReducerState } from '../../../store/reducers'

// Components
import { Button, Form, Message, Container, Modal, Icon, Header } from 'semantic-ui-react'
import PasswordFormField from '../../../components/PasswordFormField'
import PasswordStrength from '../../../components/PasswordStrength'

// Styles
import './SettingsView.scss'

function SettingsView() {
  const dispatch = useDispatch()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [userInfoHasChanged, setUserInfoHasChanged] = useState(false)
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false)

  const {
    email: userEmail,
    // locks: userLocks, 
    name: userName,
    phone: userPhone,
    // pid: userPid
  } = useSelector((state: RootReducerState) => state.user)
  let userState = useSelector((state: RootReducerState) => state.user)

  const {
    currentPassword: formCurrentPassword,
    email: formEmail,
    name: formName,
    newPassword: formNewPassword,
    phone: formPhone
  } = useSelector((state: RootReducerState) => state.ui.settings?.fields)
  const formValues = useSelector((state: RootReducerState) => state.ui.settings?.fields)

  const errorState = useSelector((state: RootReducerState) => state.ui.settings?.error)
  const deleteSuccess = useSelector((state: RootReducerState) => state.ui.settings?.deleteSuccess)
  const isLoading = useSelector((state: RootReducerState) => state.ui.settings?.isLoading)

  const blocker = useBlocker(userInfoHasChanged)

  useEffect(() => {
    if (blocker.state === "blocked" && !userInfoHasChanged) {
      blocker.reset();
    } else if (blocker.location) {
      setUnsavedChangesModalOpen(true)
    }
  }, [blocker]) // eslint-disable-line

  const onSubmitHandler = () => {
    if (userInfoHasChanged) {
      return dispatch(
        updateUser({
          name: formName,
          phone: formPhone,
          email: formEmail,
          currentPassword: formCurrentPassword,
          newPassword: formNewPassword
        })
      )
    }
  }

  const handleConfirmOpen = () => setConfirmOpen(true)
  const handleConfirmClose = () => setConfirmOpen(false)


  const handleDeleteUser = () => {
    handleConfirmClose()
    return dispatch(deleteUser(
      // { password: formCurrentPassword }
    ))
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch(
      updateSettingsFormFields({
        [name]: value
      })
    )
  }

  const handleCancel = () => {
    dispatch(
      updateSettingsFormFields({
        name: userName,
        phone: userPhone,
        email: userEmail
      })
    )
  }

  useEffect(() => {
    setUserInfoHasChanged(
      formName !== userName ||
      formEmail !== userEmail ||
      formPhone !== userPhone ||
      (formCurrentPassword?.length > 1 &&
        formNewPassword?.length > 1))

  }, [userState, formValues]) //eslint-disable-line

  useEffect(() => {
    dispatch(
      updateSettingsFormFields({
        name: userState.name,
        phone: userState.phone,
        email: userState.email
      })
    )
  }, []) //eslint-disable-line

  useEffect(() => {
    if (deleteSuccess) {
      setTimeout(() => {
        routes.navigate('/login')
        dispatch(removeAllUserData())
      }, 3000)
    }
  }, [deleteSuccess]) //eslint-disable-line

  return (
    <Container as="main" id="SettingsView" style={{ paddingTop: '4em' }}>
      <Form loading={isLoading} success={deleteSuccess} error={errorState.error != null}>
        <Form.Input fluid iconPosition="left" type="text"
          icon="user"
          label="Name"
          name="name"
          value={formName}
          onChange={handleUpdateField}
        />

        <Form.Input fluid iconPosition="left" type="text"
          icon="phone"
          label="Phone"
          placeholder="Phone"
          name="phone"
          value={formPhone || ""}
          onChange={handleUpdateField}
        />

        <Form.Input fluid iconPosition="left" type="email"
          icon="at"
          placeholder="E-mail address"
          name="email"
          value={formEmail}
          onChange={handleUpdateField}
        />

        <PasswordFormField fluid iconPosition="left" type="password"
          icon="lock"
          placeholder="Current password"
          name="currentPassword"
          value={formCurrentPassword}
          onChange={handleUpdateField}
        />

        <PasswordFormField
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="New password"
          type="password"
          name="newPassword"
          value={formNewPassword}
          onChange={handleUpdateField}
        >
          {formNewPassword?.length > 2 ? <PasswordStrength password={formNewPassword} /> : ''}
        </PasswordFormField>
        <Button.Group fluid className="settings-button-group" widths="2">
          <Button color="green" size="large" onClick={onSubmitHandler} disabled={!userInfoHasChanged || !formCurrentPassword}>
            Update info
          </Button>
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>
        </Button.Group>
        <Modal
          trigger={
            <Button color="red" className="delete-user-button" size="large" fluid onClick={handleConfirmOpen}>
              Delete account
            </Button>
          }
          open={confirmOpen}
          onClose={handleConfirmClose}
          basic
          size="small"
        >
          <Header icon="exclamation triangle" content="Confirm account deletion" />
          <Modal.Content>
            <p>This will delete your account and all lock you have created</p>
            <h3>
              <strong>This is a permanent action and can't be undone</strong>
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleConfirmClose} inverted>
              <Icon name="cancel" /> Cancel
            </Button>
            <Button color="red" onClick={handleDeleteUser}>
              <Icon name="trash alternate" /> Confirm delete
            </Button>
          </Modal.Actions>
        </Modal>
        <Message error>
          <Message.Header content="Error" />
          {typeof errorState.message === "string"
            ? <p>{errorState.message}</p>
            : Array.isArray(errorState.message) &&
            errorState.message.map(error =>
              <p>{error.message}</p>
            )
          }
        </Message>
        <Message success>
          <Message.Header content="Account deleted" />
          <p>
            <strong>Your account has now been delete!</strong>
          </p>
          <p>Sending you to login page in 3 seconds</p>
        </Message>
      </Form>

      {/* Unsaved changes Modal */}

      <Modal
        open={unsavedChangesModalOpen}
        onClose={() => setUnsavedChangesModalOpen(false)}
        basic
        size="small"
      >
        <Header icon="exclamation triangle" content="You have unsaved changes" />
        <Modal.Content>
          <h3>
            <strong>Updated info isn't saved, are you sure you want to leave?</strong>
          </h3>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setUnsavedChangesModalOpen(false)} inverted>
            <Icon name="cancel" /> Stay
          </Button>
          <Button color="red" onClick={blocker?.proceed}>
            <Icon name="trash alternate" /> Leave
          </Button>
        </Modal.Actions>
      </Modal>


      {/* <Prompt when={userInfoHasChanged} message="Updated info isn't saved, are you sure you want to leave?" /> */}
    </Container>
  )
}

export default SettingsView
