const lockCostants = {
  TYPES: {
    GET_LOCKS: 'locks/GET_LOCKS',
    GET_LOCK: 'locks/GET_LOCK',
    CREATE_LOCK: 'locks/CREATE_LOCK',
    DELETE_LOCK: 'locks/DELETE_LOCK',
    REMOVE_ME_FROM_LOCK: 'locks/REMOVE_ME_FROM_LOCK',
    UPDATE_LOCK: 'locks/UPDATE_LOCK',
    CLEAR_CURRENT_LOCK: 'locks/CLEAR_CURRENT_LOCK',
    SEND_DAY_CODE: 'locks/SEND_DAY_CODE',
    SEND_PERIOD_CODE: 'locks/SEND_PERIOD_CODE',
    SEND_SINGLE_USE_CODE: 'locks/SEND_SINGLE_USE_CODE',
    GET_DAY_CODE: 'locks/GET_DAY_CODE',
    GET_PERIOD_CODE: 'locks/GET_PERIOD_CODE',
    GET_SINGLE_USE_CODE: 'locks/GET_SINGLE_USE_CODE',
    CLEAR_DAY_CODE: 'locks/CLEAR_DAY_CODE',
    CLEAR_PERIOD_CODE: 'locks/CLEAR_PERIOD_CODE',
    CLEAR_SINGLE_USE_CODE: 'locks/CLEAR_SINGLE_USE_CODE',
  }
}

export default lockCostants
