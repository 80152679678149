import { REHYDRATE } from 'redux-persist/constants'
import { DateTime } from 'luxon'
import AuthSchema, { accessTokenType } from '../schemas/authSchema'
import authConstants from '../constants/authConstants'
import userConstants from '../constants/userConstants'
import cloneDeep from 'lodash/cloneDeep'

const initialState = cloneDeep(AuthSchema)

export default function authReducer(state = cloneDeep(initialState), action: any) {
  let newState = cloneDeep(state)

  switch (action.type) {
    case REHYDRATE:
      if (action.payload.auth?.accessToken != null) {
        const accessTokenCreatedAt = DateTime.fromISO(action.payload.auth.accessToken.createdAt)
        const ttl = action.payload.auth.accessToken.ttl

        if (DateTime.local() < accessTokenCreatedAt.plus({ seconds: ttl })) {
          return action.payload.auth as accessTokenType
        } else {
          return state
        }
      } else {
        return state
      }
    case authConstants.TYPES.REGISTER_LOCAL:
    case authConstants.TYPES.LOGIN_LOCAL:
    case authConstants.TYPES.RECOVER_LOCAL:
      if (action.meta.pending || action.error)
        return state

      newState.accessToken = action.payload

      return newState
    case userConstants.TYPES.DELETE_USER_DATA:
    case authConstants.TYPES.LOG_OUT:
    case authConstants.TYPES.ACCESS_TOKEN_EXPIRED:
      return initialState

    default:
      return state
  }
}
