import LockSchema from '../schemas/lockSchema'
import lockConstants from '../constants/lockConstants'
import authConstants from '../constants/authConstants'
import userConstants from '../constants/userConstants'
import cloneDeep from 'lodash/cloneDeep'

const initialState = cloneDeep(LockSchema)

export default function lockState(state = cloneDeep(initialState), action: any) {
  let newState = cloneDeep(state)

  switch (action.type) {
    case lockConstants.TYPES.GET_LOCKS:
      if (action.meta.pending || action.error) {
        return state
      }
      newState.managedLocks = action.payload.managedLocks
      newState.viewableLocks = action.payload.viewableLocks
      return newState

    // TODO: Add Create lock success
    case lockConstants.TYPES.CREATE_LOCK:
      if (action.meta.pending || action.error) {
        return state
      }
      return state

    // Edit lock
    case lockConstants.TYPES.GET_LOCK:
    case lockConstants.TYPES.UPDATE_LOCK:
      if (action.meta.pending || action.error) {
        return state
      }

      let managers = []
      let viewers = []

      if (action.payload.managers != null) {
        managers = action.payload.managers.map((manager: any) => manager.email)
      }

      if (action.payload.viewers != null) {
        viewers = action.payload.viewers.map((viewer: any) => viewer.email)
      }

      newState.currentLock.seed = action.payload.seed
      newState.currentLock.codeLength = action.payload.codeLength
      newState.currentLock.name = action.payload.name
      newState.currentLock.description = action.payload.description
      newState.currentLock.managers = managers
      newState.currentLock.viewers = viewers
      newState.currentLock.location = action.payload.location

      return newState
    case lockConstants.TYPES.DELETE_LOCK:
    case lockConstants.TYPES.REMOVE_ME_FROM_LOCK:      
      if (action.meta.pending || action.error) {
        return state
      }

      newState.currentLock = initialState.currentLock
      return newState

    case lockConstants.TYPES.CLEAR_CURRENT_LOCK:
      newState.currentLock = initialState.currentLock
      return newState

    case userConstants.TYPES.DELETE_USER_DATA:
    case authConstants.TYPES.LOG_OUT:
    case authConstants.TYPES.ACCESS_TOKEN_EXPIRED:
      return initialState

    default:
      return state
  }
}
