import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { createLock } from '../../../store/actions/lockActions'
import { updateCreateLockFormFields, addCreateLockViewer, removeCreateLockViewer } from '../../../store/actions/uiActions'
import { RootReducerState } from '../../../store/reducers'

// Components
import { Container, Form, Button, Label, Icon, Message } from 'semantic-ui-react'

// Styles
import './CreateLockView.scss'


function CreateLockView() {
  const dispatch = useDispatch()

  const [lockerErrorFields, setLockerErrorFields] = useState<[] | { field: string, message: string }[]>([])

  const userEmail = useSelector((state: RootReducerState) => state.user.email)
  const {
    seed,
    codeLength,
    description,
    location,
    name,
    viewer,
    viewers } = useSelector((state: RootReducerState) => state.ui.createLock.fields)
  const lockFormError = useSelector((state: RootReducerState) => state.ui.createLock.error) //eslint-disable-line
  const lockErrorMessage = useSelector((state: RootReducerState) => state.ui.createLock.error?.message)
  const lockFormIsLoading = useSelector((state: RootReducerState) => state.ui.createLock.isLoading)


  const handleFormFieldUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value
    dispatch(
      updateCreateLockFormFields({
        [name]: value
      })
    )
  }

  const handleAddViewer = () => {
    if (viewer != null && viewer.length > 5 && viewer !== userEmail) {
      dispatch(addCreateLockViewer(viewer))
    } else if (viewer === userEmail) {
      dispatch(
        updateCreateLockFormFields({
          viewer: ''
        })
      )
    }
  }

  const handleRemoveViewer = (email: string) => () => {
    dispatch(removeCreateLockViewer(email))
  }

  const handleSubmit = () => {
    dispatch(
      createLock(
        {
          seed: seed,
          lockType: "", // the backend will always make this "dayCode"
          codeLength: codeLength,
          name: name,
          description: description,
          location: location,
          viewers: viewers
        }
      )
    )
  }

  const handleSetRandomSeed = () => {
    dispatch(
      updateCreateLockFormFields({
        seed: generateRandomSeed()
      })
    )
  }

  const generateViewerLabels = () => {
    if (viewers.length === 0) {
      return <>No viewers</>
    }
    return viewers.map((viewer) => {
      return (
        <Label key={`VIEWER_LABEL:${viewer}`}>
          <Icon name="mail" />
          {viewer}
          <Icon name="delete" onClick={handleRemoveViewer(viewer)} />
        </Label>
      )
    })
  }


  const generateRandomSeed = () => {
    if (window.crypto && window.crypto.getRandomValues) {
      const cryptoArray = new Uint32Array(1)
      window.crypto.getRandomValues(cryptoArray)
      return String(cryptoArray[0] % 100000000)
    } else {
      return String(Math.random()).slice(-8)
    }
  }

  useEffect(() => {
    const errorMessage = lockErrorMessage
    if (lockErrorMessage !== null &&
      typeof lockErrorMessage === "object" &&
      lockErrorMessage.length > 0) {
      let fieldErrors = errorMessage == null || typeof errorMessage === 'string'
        ? []
        : errorMessage.map((errorObject) => {
          let message = errorObject.message.replace(/^("([a-zA-Z]+)+" )/, "")
          message = message[0].toUpperCase() + message.slice(1)

          return {
            field: errorObject.path[0],
            message: message
          }
        })

      setLockerErrorFields(fieldErrors)
    }
  }, [lockErrorMessage])

  return (
    <Container as="main" id="CreateLockView" style={{ paddingTop: '4em' }}>
      <Form loading={lockFormIsLoading} error={lockErrorMessage !== null}>
        <Form.Input
          fluid
          label="Seed"
          name="seed"
          type="number"
          placeholder="Seed e.g. 1234"
          value={seed != null ? seed : ''}
          onChange={handleFormFieldUpdate}
          error={
            lockerErrorFields.filter(error => error.field === "seed").length > 0
              ? lockerErrorFields.filter(error => error.field === "seed")[0].message
              : false
          }
          action={{
            color: "orange",
            content: "Random seed",
            onClick: handleSetRandomSeed
          }}
        >
        </Form.Input>
        <Form.Input
          label="Code length"
          name="codeLength"
          type="number"
          min={4}
          max={8}
          value={codeLength}
          onChange={handleFormFieldUpdate}
          error={
            lockerErrorFields.filter(error => error.field === "codeLength").length > 0
              ? lockerErrorFields.filter(error => error.field === "codeLength")[0].message
              : false
          }
        />
        <Form.Input
          label="Name"
          type="text"
          name="name"
          value={name}
          min="4"
          max="8"
          onChange={handleFormFieldUpdate}
          error={
            lockerErrorFields.filter(error => error.field === "name").length > 0
              ? lockerErrorFields.filter(error => error.field === "name")[0].message
              : false
          }
        />
        <Form.Input
          label="Description"
          name="description"
          type="text"
          value={description}
          onChange={handleFormFieldUpdate}
          error={
            lockerErrorFields.filter(error => error.field === "description").length > 0
              ? lockerErrorFields.filter(error => error.field === "description")[0].message
              : false
          }
        />
        <Form.Input
          label="Location"
          name="location"
          disabled
          type="text"
          value="Not available at the moment"
          onChange={handleFormFieldUpdate}
          error={
            lockerErrorFields.filter(error => error.field === "location").length > 0
              ? lockerErrorFields.filter(error => error.field === "location")[0].message
              : false
          }
        />

        <div className="lock-viewers">
          <div className="viewer-labels field">
            <label>Viewers</label>
            {generateViewerLabels()}
          </div>
          <Form.Input label="Add viewer" name="viewer" type="email" value={viewer} placeholder="Email address" onChange={handleFormFieldUpdate} />
          <Button fluid primary onClick={handleAddViewer}>
            Add viewer
          </Button>
        </div>

        <Button fluid color="green" type="submit" onClick={handleSubmit}>
          Create Lock
        </Button>
        {typeof lockErrorMessage === "string" &&
          <Message error header="Error" content={lockErrorMessage} />
        }
      </Form>
    </Container>
  )
}

export default CreateLockView
