import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { loginLocal } from '../../../store/actions/authActions'
import { updateLoginFormFields } from '../../../store/actions/uiActions'
import { RootReducerState } from '../../../store/reducers'

// Components
import { Button, Form, Grid, Message, Header, Divider } from 'semantic-ui-react'
import PasswordFormField from '../../../components/PasswordFormField'
import CcLogo from '../../../components/CcLogo'

// Styles
import './LoginView.scss'

function LoginView() {
  const dispatch = useDispatch()

  const [fieldsFilled, setFieldsFilled] = useState(false)

  const formError = useSelector((state: RootReducerState) => state.ui.authentication?.login.error) // eslint-disable-line
  const formErrorMessage = useSelector((state: RootReducerState) => state.ui.authentication?.login.error?.message)
  const formIsLoading = useSelector((state: RootReducerState) => state.ui.authentication?.login.isLoading)
  const formEmail = useSelector((state: RootReducerState) => state.ui.authentication?.login.fields.email)
  const formPassword = useSelector((state: RootReducerState) => state.ui.authentication?.login.fields.password)


  const onSubmitHandler = () => {
    if (fieldsFilled) {
      dispatch(loginLocal({ email: formEmail, password: formPassword }))
    }
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(
      updateLoginFormFields({
        [name]: value
      })
    )
  }

  useEffect(() => {
    setFieldsFilled(!!formEmail && !!formPassword)
  }, [formEmail, formPassword])

  return (
    <main id="LoginView">
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450, width: '100vw' }}>
          <CcLogo className="conlan-logo" />
          <Header as="h2" color="blue" textAlign="center">
            Log-in to your account
          </Header>

          <Form size="large" className="top attached segment"
            loading={formIsLoading}
            error={formErrorMessage != null}>
            <Form.Input
              fluid
              icon="at"
              iconPosition="left"
              placeholder="E-mail address"
              type="email"
              value={formEmail}
              onChange={handleUpdateField}
              name="email" />
            <PasswordFormField
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={formPassword}
              onChange={handleUpdateField}
              name="password"
            />

            <Button primary fluid size="large" onClick={onSubmitHandler} disabled={!fieldsFilled}>
              Login
            </Button>

            <Message error header="Error" content={typeof formErrorMessage === "string" ? formErrorMessage : null} />
          </Form>
          <Message attached="bottom">
            <Link to="/reset">Forgot password?</Link>
            <Divider />
            Don't have an account? <Link to="/register">Sign up here</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </main>
  )
}

export default LoginView
