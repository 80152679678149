export type userSchemaType = {
  pid: string,
  name: string,
  phone: string,
  email: string,
  locks: []

}

const userSchema: userSchemaType = {
  pid: '',
  name: '',
  phone: '',
  email: '',
  locks: []
}

export default userSchema