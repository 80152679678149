export type accessTokenType = {
  accessToken: {
    id: string,
    ttl: number,
    scopes: [
      'platform'
    ],
    createdAt: string,
    user: {
      pid: string,
      name: string,
      phone: null | string,
      email: string
    }
  } | null
}

const accessToken: accessTokenType = {
  accessToken: null
}

const authSchema = {
  ...accessToken
}

export default authSchema
