import React from 'react'

import { NavLink, Link } from 'react-router-dom'
import { Menu, Dropdown } from 'semantic-ui-react'
import CcLogo from '../../CcLogo'

const DesktopMenu = ({userEmail, handleLogout}: {userEmail: string, handleLogout: () => void }) => {

  const blurElement = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.blur()
  }
  
  return (
    <>
      <Menu.Item as={Link} to="/">
        <CcLogo className="access-bar-logo" />
      </Menu.Item>
      <Dropdown item simple closeOnBlur text="Lock">
        <Dropdown.Menu>
          <Dropdown.Item as={NavLink} onClick={blurElement} to="/" >
            View Locks
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} onClick={blurElement} to="/locks/create">
            Create Lock
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Menu position="right">
        <Dropdown item simple closeOnBlur text="Settings">
          <Dropdown.Menu>
            <Dropdown.Item disabled>
              Signed in as <strong>{userEmail}</strong>
            </Dropdown.Item>
            <Dropdown.Item as={NavLink} onClick={blurElement} to="/settings">
              Go to settings
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </>
  )
}

export default DesktopMenu
