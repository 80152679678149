import lockConstants from '../constants/lockConstants'
import { Dispatch } from "redux"
import routes from "../../routes/index"

export function getLocks() {
  return (dispatch: Dispatch) => {

    return dispatch({
      type: lockConstants.TYPES.GET_LOCKS,
      meta: {
        type: 'api',
        endpoint: '/locks',
        method: 'GET'
      }
    })
  }
}

type createLockTypes = {
  seed: string,
  lockType: string,
  codeLength: string,
  name: string,
  description: string,
  location: object,
  viewers: string[]
}

export function createLock({ seed = '', lockType = '', codeLength = '', name = '', description = '', location = {}, viewers = [] }: createLockTypes) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.CREATE_LOCK,
      meta: {
        type: 'api',
        endpoint: '/locks/create',
        method: 'POST',
        body: {
          seed,
          codeLength,
          lockType,
          name,
          description,
          location,
          viewers
        },
        navigate: (response: any) => routes.navigate(`/locks/${response.data.pid}/edit`)
      }
    })
  }
}

type updateLockTypes = {
  lockPid: string | undefined,
  seed: string,
  lockType: string,
  codeLength: string,
  name: string,
  description: string,
  location: object,
  viewers: string[]
}

export function updateLock({ lockPid = '', seed = '', lockType = '', codeLength = '', name = '', description = '', location = {}, viewers = [] }: updateLockTypes) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.UPDATE_LOCK,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}`,
        method: 'PUT',
        body: {
          seed,
          codeLength,
          lockType,
          name,
          description,
          location,
          viewers
        }
      }
    })
  }
}

export function getLock({ lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.GET_LOCK,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}`,
        method: 'GET'
      }
    })
  }
}

export function deleteLock({ lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.DELETE_LOCK,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}`,
        method: 'DELETE',
        navigate: () => routes.navigate('/')
      }
    })
  }
}

export function removeFromLock({ lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.REMOVE_ME_FROM_LOCK,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}/remove-me`,
        method: 'DELETE',
        navigate: () => routes.navigate('/', { replace: true })
      }
    })
  }
}

export const clearCurrentLock = () => ({
  type: lockConstants.TYPES.CLEAR_CURRENT_LOCK
})

export const clearDayCode = () => ({
  type: lockConstants.TYPES.CLEAR_DAY_CODE
})

export const clearPeriodCode = () => ({
  type: lockConstants.TYPES.CLEAR_PERIOD_CODE
})

export const clearSingleUseCode = () => ({
  type: lockConstants.TYPES.CLEAR_SINGLE_USE_CODE
})

// export function sendDayCode({ email = '', accessDay = '', lockPid = '' }) {
//   return (dispatch: Dispatch) => {
//     return dispatch({
//       type: lockConstants.TYPES.SEND_DAY_CODE,
//       meta: {
//         type: 'api',
//         endpoint: `/locks/${lockPid}/send-day-code`,
//         method: 'POST',
//         body: {
//           email,
//           accessDay
//         }
//       }
//     })
//   }
// }

// export function sendPeriodCode({ email = '', startIsoDate = '', endIsoDate = '', lockPid = '' }) {
//   return (dispatch: Dispatch) => {
//     return dispatch({
//       type: lockConstants.TYPES.SEND_PERIOD_CODE,
//       meta: {
//         type: 'api',
//         endpoint: `/locks/${lockPid}/send-period-code`,
//         method: 'POST',
//         body: {
//           email,
//           startIsoDate,
//           endIsoDate
//         }
//       }
//     })
//   }
// }

// export function sendSingleUseCode({ email = '', lockPid = '' }) {
//   return (dispatch: Dispatch) => {
//     return dispatch({
//       type: lockConstants.TYPES.SEND_SINGLE_USE_CODE,
//       meta: {
//         type: 'api',
//         endpoint: `/locks/${lockPid}/send-single-use-code`,
//         method: 'POST',
//         body: {
//           email
//         }
//       }
//     })
//   }
// }

export function getDayCode({ accessDay = '', lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.GET_DAY_CODE,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}/get-day-code`,
        method: 'POST',
        body: {
          accessDay
        }
      }
    })
  }
}
export function getPeriodCode({ startIsoDate = '', endIsoDate = '', lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.GET_PERIOD_CODE,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}/get-period-code`,
        method: 'POST',
        body: {
          startIsoDate,
          endIsoDate
        }
      }
    })
  }
}

export function getSingleUseCode({ lockPid = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: lockConstants.TYPES.GET_SINGLE_USE_CODE,
      meta: {
        type: 'api',
        endpoint: `/locks/${lockPid}/get-single-use-code`,
        method: 'POST',
        body: {
        }
      }
    })
  }
}
