import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { registerLocal } from '../../../store/actions/authActions'
import { clearRegisterForm } from '../../../store/actions/uiActions'
import { updateRegisterFormFields } from '../../../store/actions/uiActions'

// Components
import { Button, Form, Grid, Message, Header, Divider } from 'semantic-ui-react'
import PasswordFormField from '../../../components/PasswordFormField'
import PasswordStrength from '../../../components/PasswordStrength'
import CcLogo from '../../../components/CcLogo'

// Styles
import './RegisterView.scss'


import { RootReducerState } from '../../../store/reducers'

function RegisterView() {
  const dispatch = useDispatch();

  const [hasClickedRegister, setHasClickedRegister] = useState(false)
  const [allFieldsFilled, setAllFieldsFilled] = useState(false)
  const [formFieldIsDisabled, setFormFieldIsDisabled] = useState(false)

  const formError = useSelector((state: RootReducerState) => state.ui.authentication.register.error)
  const formErrorMessage = useSelector((state: RootReducerState) => state.ui.authentication.register.error.message)
  const formIsLoading = useSelector((state: RootReducerState) => state.ui.authentication.register.isLoading)
  const userEmail = useSelector((state: RootReducerState) => state.user.email)
  const formName = useSelector((state: RootReducerState) => state.ui.authentication.register.fields.name)
  const formEmail = useSelector((state: RootReducerState) => state.ui.authentication.register.fields.email)
  const formPassword = useSelector((state: RootReducerState) => state.ui.authentication.register.fields.password)
  const formPhone = useSelector((state: RootReducerState) => state.ui.authentication.register.fields.phone)

  const onSubmitHandler = () => {
    setHasClickedRegister(true)
    dispatch(registerLocal({ email: formEmail, phone: formPhone, name: formName, password: formPassword }))
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    dispatch(
      updateRegisterFormFields({
        [name]: value
      })
    )
  }
  useEffect(() => {
    setAllFieldsFilled(formEmail.length > 0 && formName.length > 0 && formPassword.length > 0)
  }, [formEmail, formName, formPassword])


  useEffect(() => {
    setFormFieldIsDisabled(formError == null && userEmail != null && hasClickedRegister === true)
  }, [formError, userEmail, hasClickedRegister])


  useEffect(() => {
    return () => { dispatch(clearRegisterForm()) }
  }, []) //eslint-disable-line

  return (
    <main id="RegisterView">
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450, width: '100vw' }}>
          <CcLogo className="conlan-logo" />
          <Header as="h2" color="blue" textAlign="center">
            Register new account
          </Header>
          <Form
            size="large"
            className="top attached segment"
            loading={formIsLoading}
            error={formErrorMessage !== null}
            success={formError.error == null && userEmail != null && hasClickedRegister === true}
          >
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Name"
              type="text"
              name="name"
              value={formName}
              onChange={handleUpdateField}
              disabled={formFieldIsDisabled}
            />
            <Form.Input
              fluid
              icon="phone"
              iconPosition="left"
              placeholder="Phone"
              type="tel"
              name="phone"
              value={formPhone}
              onChange={handleUpdateField}
              disabled={formFieldIsDisabled}
            />
            <Form.Input
              fluid
              icon="at"
              iconPosition="left"
              placeholder="E-mail address"
              type="email"
              name="email"
              value={formEmail}
              onChange={handleUpdateField}
              disabled={formFieldIsDisabled}
            />
            <PasswordFormField
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              name="password"
              value={formPassword}
              onChange={handleUpdateField}
              disabled={formFieldIsDisabled}
            >
              {formPassword.length > 2 ? <PasswordStrength password={formPassword} /> : ''}
            </PasswordFormField>

            <Button primary fluid size="large" onClick={onSubmitHandler} disabled={!allFieldsFilled}>
              Sign up
            </Button>

            <Message error header="Error" content={typeof formErrorMessage === "string" ? formErrorMessage : null} />

            <Message success>
              <Message.Header>Success</Message.Header>
              An confirmation email has been sent to <strong>{userEmail}</strong>
              <Divider />
              <strong>
                <Link to="/locks/create">Click here </Link>
              </strong>
              to create your first lock!
            </Message>
          </Form>
          <Message attached="bottom">
            Already have an account? <Link to="/login">Sign in here</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </main>
  )
}

export default RegisterView
