export type lockSchemaType = {
  managedLocks: [],
  viewableLocks: [],
  currentLock: {
    seed: string,
    codeLength: string,
    name: string,
    description: string,
    location: {},
    viewers: string[],
    managers: string[]
  }
}

const lockSchema: lockSchemaType = {
  managedLocks: [],
  viewableLocks: [],
  currentLock: {
    seed: '',
    codeLength: '4',
    name: '',
    description: '',
    location: {},
    viewers: [],
    managers: []
  }
}

export default lockSchema