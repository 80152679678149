import { combineReducers } from "redux"

// Stores
import authReducer from './authReducer'
import userReducer from './userReducer'
import uiReducer from './uiReducer'
import lockReducer from './lockReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  ui: uiReducer,
  locks: lockReducer
})

export default rootReducer

export type RootReducerState = ReturnType<typeof rootReducer>
