
import { } from 'redux-persist'
import { REHYDRATE } from 'redux-persist/constants'

import UiSchema, { errorState } from '../schemas/uiSchema'
import authConstants from '../constants/authConstants'
import userConstants from '../constants/userConstants'
import uiConstants from '../constants/uiConstants'
import lockConstants from '../constants/lockConstants'
import cloneDeep from 'lodash/cloneDeep'

const initialState = cloneDeep(UiSchema)

function addPayloadToFields(obj: any, payload: any) {
  obj[Object.keys(payload)[0]] = Object.values(payload)[0]
}

export default function uiState(state = cloneDeep(initialState), action: any) {
  let newState = cloneDeep(state)

  switch (action.type) {
    case REHYDRATE:
      newState.isHydrated = true;
      return newState

    // Login
    case uiConstants.TYPES.UPDATE_LOGIN_FORM_FIELDS:
      addPayloadToFields(newState.authentication?.login.fields, action.payload)
      // newState.authentication.login.fields[ Object.keys(action.payload)[0]: any] = Object.values(action.payload)[0]
      return newState

    case authConstants.TYPES.LOGIN_LOCAL:
      if (action.meta.pending) {
        newState.authentication.login.isLoading = true
        newState.authentication.login.error = errorState
        return newState
      }
      if (action.error) {
        newState.authentication.login.error = action.payload
        newState.authentication.login.isLoading = false
        return newState
      }

      newState.authentication.login.isLoading = false;
      newState.authentication.login.fields = {
        email: '',
        password: ''
      }
      return newState

    // Register
    case uiConstants.TYPES.UPDATE_REGISTER_FORM_FIELDS:
      addPayloadToFields(newState.authentication.register.fields, action.payload)
      // newState.authentication.register.fields[Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      return newState


    case authConstants.TYPES.REGISTER_LOCAL:
      if (action.meta.pending) {
        newState.authentication.register.isLoading = true
        newState.authentication.register.error = errorState
        return newState
      }
      if (action.error) {
        newState.authentication.register.error = action.payload
        newState.authentication.register.isLoading = false
        return newState
      }
      newState.authentication.register.isLoading = false
      return newState

    case uiConstants.TYPES.CLEAR_REGISTER_FORM:
      newState.authentication.register.fields = {
        name: '',
        phone: '',
        email: '',
        password: ''
      }
      return newState

    // Recover
    case uiConstants.TYPES.UPDATE_RECOVER_FORM_FIELDS:
      addPayloadToFields(newState.authentication.recover.fields, action.payload)
      // newState.authentication.recover.fields[Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      return newState

    case authConstants.TYPES.RECOVER_LOCAL:
      if (action.meta.pending) {
        newState.authentication.recover.isLoading = true
        newState.authentication.recover.error = errorState;
        return newState
      }
      if (action.error) {
        newState.authentication.recover.error = action.payload
        newState.authentication.recover.isLoading = false
        return newState
      }
      newState.authentication.recover.isLoading = false
      newState.authentication.recover.fields = {
        password: '',
        passwordRepeat: ''
      }
      return newState

    // Reset
    case uiConstants.TYPES.UPDATE_RESET_FORM_FIELDS:
      addPayloadToFields(newState.authentication.reset.fields, action.payload)
      // newState.authentication.reset.fields[Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      return newState

    case authConstants.TYPES.RESET_LOCAL:
      if (action.meta.pending) {
        newState.authentication.reset.isLoading = true
        newState.authentication.reset.error = errorState
        return newState
      }
      if (action.error) {
        newState.authentication.reset.error = action.payload
        newState.authentication.reset.isLoading = false
        return newState
      }
      newState.authentication.reset.isLoading = false
      return newState

    case uiConstants.TYPES.CLEAR_RESET_FORM_FIELDS:
      newState.authentication.reset.error = errorState
      newState.authentication.reset.fields = {
        email: ''
      }
      return newState

    // Lock
    case lockConstants.TYPES.GET_LOCKS:
      if (action.meta.pending) {
        newState.locks.isLoading = true;
        newState.locks.error = errorState;
        return newState
      }
      if (action.error) {
        newState.locks.error = action.payload
        newState.locks.isLoading = false
        return newState
      }
      newState.locks.isLoading = false
      return newState

    // Create lock
    case uiConstants.TYPES.UPDATE_CREATE_LOCK_FORM_FIELDS:
      addPayloadToFields(newState.createLock.fields, action.payload)
      // newState.createLock.fields[Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      return newState
    case uiConstants.TYPES.ADD_CREATE_LOCK_VIEWER:
      newState.createLock.fields.viewers.push(String(action.payload).toLowerCase())
      newState.createLock.fields.viewer = ''
      return newState
    case uiConstants.TYPES.REMOVE_CREATE_LOCK_VIEWER:
      newState.createLock.fields.viewers =
        newState.createLock.fields.viewers.filter(viewer => viewer !== String(action.payload).toLowerCase())
      return newState

    case lockConstants.TYPES.CREATE_LOCK:
      if (action.meta.pending) {
        newState.createLock.isLoading = true
        newState.createLock.error = errorState
        return newState
      }
      if (action.error) {
        newState.createLock.error = action.payload
        newState.createLock.isLoading = false
        return newState
      }

      newState.createLock.isLoading = false
      newState.createLock.fields = {
        seed: '',
        codeLength: '4',
        name: '',
        description: '',
        location: {},
        viewer: '',
        viewers: []
      }
      return newState

    // Edit lock
    case lockConstants.TYPES.GET_LOCK:
    case lockConstants.TYPES.UPDATE_LOCK:
      if (action.meta.pending) {
        newState.editLock.isLoading = true
        newState.editLock.error = errorState
        // newState.editLock.sendDayCode.error = errorState
        // newState.editLock.sendDayCode.success = false
        // newState.editLock.sendPeriodCode.error = errorState
        // newState.editLock.sendPeriodCode.success = false
        // newState.editLock.sendSingleUseCode.error = errorState
        // newState.editLock.sendSingleUseCode.success = false
        newState.editLock.getDayCode.error = errorState
        newState.editLock.getDayCode.success = false
        newState.editLock.getPeriodCode.error = errorState
        newState.editLock.getPeriodCode.success = false
        newState.editLock.getSingleUseCode.error = errorState
        newState.editLock.getSingleUseCode.success = false
        return newState
      }
      if (action.error) {

        newState.editLock.error = action.payload
        newState.editLock.isLoading = false
        return newState
      }

      let managers = []
      let viewers = []

      if (action.payload.managers != null) {
        managers = action.payload.managers.map((manager: any) => manager.email)
      }

      if (action.payload.viewers != null) {
        viewers = action.payload.viewers.map((viewer: any) => viewer.email)
      }

      newState.editLock.fields.seed = action.payload.seed
      newState.editLock.fields.code = action.payload.code
      newState.editLock.fields.codeLength = action.payload.codeLength
      newState.editLock.fields.description = action.payload.description
      newState.editLock.fields.location = action.payload.location
      newState.editLock.fields.name = action.payload.name
      newState.editLock.fields.managers = managers
      newState.editLock.fields.viewers = viewers
      newState.editLock.isLoading = false

      return newState

    case uiConstants.TYPES.UPDATE_EDIT_LOCK_FORM_FIELDS:


      addPayloadToFields(newState.editLock.fields, action.payload)
      return newState

    case uiConstants.TYPES.ADD_EDIT_LOCK_VIEWER:
      let viewerExists = newState.editLock.fields.viewers.find(v => v === action.payload.toLowerCase())

      if (!viewerExists) {
        newState.editLock.fields.viewers.push(action.payload.toLowerCase())
      }
      newState.editLock.fields.viewer = "";
      return newState

    case uiConstants.TYPES.REMOVE_EDIT_LOCK_VIEWER:
      newState.editLock.fields.viewers =
        newState.editLock.fields.viewers.filter(viewer => viewer !== action.payload)
      return newState

    case lockConstants.TYPES.REMOVE_ME_FROM_LOCK:
    case lockConstants.TYPES.DELETE_LOCK:
      if (action.meta.pending) {
        newState.editLock.isLoading = true
        newState.editLock.error = errorState
        // newState.editLock.sendDayCode.error = errorState
        // newState.editLock.sendDayCode.success = false
        // newState.editLock.sendPeriodCode.error = errorState
        // newState.editLock.sendPeriodCode.success = false
        // newState.editLock.sendSingleUseCode.error = errorState
        // newState.editLock.sendSingleUseCode.success = false
        newState.editLock.getDayCode.error = errorState
        newState.editLock.getDayCode.success = false
        newState.editLock.getPeriodCode.error = errorState
        newState.editLock.getPeriodCode.success = false
        newState.editLock.getSingleUseCode.error = errorState
        newState.editLock.getSingleUseCode.success = false
        return newState
      }
      if (action.error) {
        newState.editLock.error = action.payload
        newState.editLock.isLoading = false
        return newState
      }
      newState.editLock.fields = {
        seed: '',
        code: '',
        codeLength: '4',
        name: '',
        description: '',
        location: {},
        viewer: '',
        viewers: [],
        managers: []
      }
      return newState

    case lockConstants.TYPES.CLEAR_CURRENT_LOCK:
      newState.editLock.fields = {
        seed: '',
        code: '',
        codeLength: '4',
        name: '',
        description: '',
        location: {},
        viewer: '',
        viewers: [],
        managers: []
      }
      return newState

    // case lockConstants.TYPES.SEND_DAY_CODE:
    //   if (action.meta.pending) {
    //     newState.editLock.sendDayCode.isSending = true
    //     newState.editLock.sendDayCode.error = errorState
    //     newState.editLock.sendDayCode.success = false
    //     return newState
    //   }
    //   if (action.error) {
    //     newState.editLock.sendDayCode.isSending = false
    //     newState.editLock.sendDayCode.error = action.payload
    //     return newState
    //   }
    //   newState.editLock.sendDayCode.isSending = false
    //   newState.editLock.sendDayCode.success = true
    //   return newState

    // case lockConstants.TYPES.SEND_PERIOD_CODE:
    //   if (action.meta.pending) {
    //     newState.editLock.sendPeriodCode.isSending = true
    //     newState.editLock.sendPeriodCode.error = errorState
    //     newState.editLock.sendPeriodCode.success = false
    //     return newState
    //   }
    //   if (action.error) {
    //     newState.editLock.sendPeriodCode.isSending = false
    //     newState.editLock.sendPeriodCode.error = action.payload
    //     return newState
    //   }
    //   newState.editLock.sendPeriodCode.isSending = false
    //   newState.editLock.sendPeriodCode.success = true
    //   return newState

    // case lockConstants.TYPES.SEND_SINGLE_USE_CODE:
    //   if (action.meta.pending) {
    //     newState.editLock.sendSingleUseCode.isSending = true
    //     newState.editLock.sendSingleUseCode.error = errorState
    //     newState.editLock.sendSingleUseCode.success = false
    //     return newState
    //   }
    //   if (action.error) {
    //     newState.editLock.sendSingleUseCode.isSending = false
    //     newState.editLock.sendSingleUseCode.error = action.payload
    //     return newState
    //   }
    //   newState.editLock.sendSingleUseCode.isSending = false
    //   newState.editLock.sendSingleUseCode.success = true
    //   return newState

    //#region get codes
    case lockConstants.TYPES.GET_DAY_CODE:
      if (action.meta.pending) {
        newState.editLock.getDayCode.isGetting = true
        newState.editLock.getDayCode.error = errorState
        newState.editLock.getDayCode.success = false

        return newState
      }
      if (action.error) {
        newState.editLock.getDayCode.isGetting = false
        newState.editLock.getDayCode.error = action.payload
        return newState
      }
      newState.editLock.getDayCode.isGetting = false
      newState.editLock.getDayCode.success = true
      newState.editLock.getDayCode.fields = action.payload

      return newState

    case lockConstants.TYPES.GET_PERIOD_CODE:
      if (action.meta.pending) {
        newState.editLock.getPeriodCode.isGetting = true
        newState.editLock.getPeriodCode.error = errorState
        newState.editLock.getPeriodCode.success = false

        return newState
      }
      if (action.error) {
        newState.editLock.getPeriodCode.isGetting = false
        newState.editLock.getPeriodCode.error = action.payload
        return newState
      }
      newState.editLock.getPeriodCode.isGetting = false
      newState.editLock.getPeriodCode.success = true
      newState.editLock.getPeriodCode.fields = action.payload

      return newState

    case lockConstants.TYPES.GET_SINGLE_USE_CODE:
      if (action.meta.pending) {
        newState.editLock.getSingleUseCode.isGetting = true
        newState.editLock.getSingleUseCode.error = errorState
        newState.editLock.getSingleUseCode.success = false

        return newState
      }
      if (action.error) {
        newState.editLock.getSingleUseCode.isGetting = false
        newState.editLock.getSingleUseCode.error = action.payload
        return newState
      }
      newState.editLock.getSingleUseCode.isGetting = false
      newState.editLock.getSingleUseCode.success = true
      newState.editLock.getSingleUseCode.fields = action.payload

      return newState
    //#endregion


    //#region clear codes
    case lockConstants.TYPES.CLEAR_DAY_CODE:
      newState.editLock.getDayCode.error = errorState
      newState.editLock.getDayCode.isGetting = false
      newState.editLock.getDayCode.success = false

      newState.editLock.getDayCode.fields = {
        dayCode: "",
        expiryDate: ""
      }
      return newState

    case lockConstants.TYPES.CLEAR_PERIOD_CODE:
      newState.editLock.getPeriodCode.error = errorState
      newState.editLock.getPeriodCode.isGetting = false
      newState.editLock.getPeriodCode.success = false

      newState.editLock.getPeriodCode.fields = {
        periodCode: "",
        validFrom: "",
        validTo: ""
      }
      return newState

    case lockConstants.TYPES.CLEAR_SINGLE_USE_CODE:
      newState.editLock.getSingleUseCode.error = errorState
      newState.editLock.getSingleUseCode.isGetting = false
      newState.editLock.getSingleUseCode.success = false

      newState.editLock.getSingleUseCode.fields = {
        singleUseCode: "",
        expiryDate: "",
        codesLeft: ""
      }
      return newState
    //#endregion


    //#region Settings
    case uiConstants.TYPES.UPDATE_SETTINGS_FORM_FIELDS:
      newState.settings.fields = { ...newState.settings.fields, ...action.payload }
      return newState

    case userConstants.TYPES.UPDATE_USER_INFO:

      if (action.payload?.statusCode === 401) {
        return newState
      }

      if (action.meta.pending) {
        newState.settings.isLoading = true
        newState.settings.error = errorState
        return newState
      }
      if (action.error) {
        newState.settings.error = action.payload
        newState.settings.isLoading = false
        return newState
      }

      newState.settings.isLoading = false
      newState.settings.fields.currentPassword = ""
      newState.settings.fields.newPassword = ""
      return newState

    case userConstants.TYPES.DELETE_USER:
      if (action.meta.pending) {
        newState.settings.isLoading = true
        newState.settings.error = errorState
        return newState
      }
      if (action.error) {
        newState.settings.error = action.payload
        newState.settings.isLoading = false
        return newState
      }
      newState.settings.deleteSuccess = true
      newState.settings.isLoading = false
      return newState
    //#endregion

    case userConstants.TYPES.DELETE_USER_DATA:
    case authConstants.TYPES.LOG_OUT:
      // case authConstants.TYPES.ACCESS_TOKEN_EXPIRED:
      return { ...initialState, isHydrated: true }

    default:
      return state
  }
}
