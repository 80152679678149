import authConstants from '../constants/authConstants'
import { Dispatch } from "redux"
import routes from "../../routes/index"

export function loginLocal({ email = '', password = '' }) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: authConstants.TYPES.LOGIN_LOCAL,
      meta: {
        type: 'api',
        endpoint: '/login/local',
        method: 'POST',
        body: {
          email,
          password
        },
        navigate: () => routes.navigate('/')
      }
    })
  }
}

export const registerLocal = ({ name = '', phone = '', email = '', password = '' }) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: authConstants.TYPES.REGISTER_LOCAL,
      meta: {
        type: 'api',
        endpoint: '/register/local',
        method: 'POST',
        body: {
          name,
          phone,
          email,
          password
        }
      }
    })
  }
}

export const recoverLocal = ({ recoverToken = '', password = '' }) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: authConstants.TYPES.RECOVER_LOCAL,
      meta: {
        type: 'api',
        endpoint: '/recover',
        method: 'POST',
        body: {
          recoverToken,
          password
        },
        navigate: () => routes.navigate('/')
      }
    })
  }
}

export const resetLocal = ({ email = '' }) => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: authConstants.TYPES.RESET_LOCAL,
      meta: {
        type: 'api',
        endpoint: '/reset',
        method: 'POST',
        body: {
          email
        }
      }
    })
  }
}

export const logOut = () => ({
  type: authConstants.TYPES.LOG_OUT
})
