import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { resetLocal } from '../../../store/actions/authActions'
import { updateResetFormFields, clearResetFormFields } from '../../../store/actions/uiActions'
import { RootReducerState } from '../../../store/reducers'
// Components
import { Button, Form, Grid, Message, Header } from 'semantic-ui-react'
import CcLogo from '../../../components/CcLogo'

// Styles
import './ResetView.scss'

function RecoverView() {
  const dispatch = useDispatch();

  const [hasClickedReset, setHasClickedReset] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)


  const formError = useSelector((state: RootReducerState) => state.ui.authentication.reset.error)
  const formErrorMessage = useSelector((state: RootReducerState) => state.ui.authentication.reset.error.message)
  const formIsLoading = useSelector((state: RootReducerState) => state.ui.authentication.reset.isLoading)
  const formEmail = useSelector((state: RootReducerState) => state.ui.authentication.reset.fields.email)


  const onSubmitHandler = () => {
    setHasClickedReset(true)
    dispatch(resetLocal({ email: formEmail }))
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateResetFormFields({
        [e.target.name]: e.target.value
      })
    )
  }

  useEffect(() => {
    setIsDisabled(formError == null && hasClickedReset)
  }, [formError, hasClickedReset])

  useEffect(() => {
    return () => {
      dispatch(clearResetFormFields())
    }
  }, []) // eslint-disable-line


  return (
    <main id="ResetView">
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450, width: '100vw' }}>
          <CcLogo className="conlan-logo" />
          <Header as="h2" color="blue" textAlign="center">
            Enter email to reset
          </Header>
          <Form
            size="large"
            className="top attached segment"
            loading={formIsLoading}
            error={formError.message != null}
            success={formError == null && hasClickedReset && formEmail.length > 1}
          >
            <Form.Input
              fluid
              icon="at"
              iconPosition="left"
              placeholder="E-mail address"
              type="email"
              name="email"
              value={formEmail}
              onChange={handleUpdateField}
              disabled={isDisabled}
            />

            <Button primary fluid size="large" onClick={onSubmitHandler} disabled={formEmail.length < 1 || isDisabled}>
              Reset password
            </Button>
            <Message error header="Error" content={formErrorMessage?.toString()} />
            <Message
              success
              header="Success"
              content={
                <p>
                  An email has been sent to <strong>{formEmail}</strong> with further instructions
                </p>
              }
            />
          </Form>
          <Message attached="bottom">
            <Link to="/login">Go to login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </main>
  )
}

export default RecoverView
