import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { getLocks } from '../../../store/actions/lockActions'
import { RootReducerState } from '../../../store/reducers'
// Components
import { Card, Container, Divider, Segment, Grid, Icon, Header, Button, Loader } from 'semantic-ui-react'
import CodeCard from './CodeCard'
import { Link } from 'react-router-dom'

// Styles
import './HomeView.scss'

function HomeView() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)

  const locksState = useSelector((state: RootReducerState) => state.locks)
  const isLoading = useSelector((state: RootReducerState) => state.ui.locks?.isLoading)
  const error = useSelector((state: RootReducerState) => state.ui.locks?.error) //eslint-disable-line

  const dispatch = useDispatch();

  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    const intervalSeconds = 30;
    handleGetLocks();
    window.addEventListener("resize", updatePredicate);
    intervalRef.current = setInterval(handleGetLocks, intervalSeconds * 1000)

    return () => {
      window.removeEventListener("resize", updatePredicate);
      clearInterval(intervalRef.current);
    }
  }, []) //eslint-disable-line


  const updatePredicate = () => {
    setIsDesktop(window.innerWidth > 768)
  }

  const handleGetLocks = () => {
    dispatch(getLocks())
  }

  if (isLoading && locksState.managedLocks?.length === 0 && locksState.viewableLocks?.length === 0)
    return <Loader size="huge" active />

  return (
    locksState.viewableLocks?.length < 1 && locksState.managedLocks?.length < 1 ?
      <Container as="main" id="HomeView" style={{ paddingTop: '5em' }}>
        <Segment placeholder>
          <Grid columns={2} stackable textAlign="center">

            {isDesktop && <Divider vertical>No locks</Divider>}

            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header icon>
                  <Icon name="refresh" />
                  Refresh locks
                </Header>
                <Button color="green" onClick={handleGetLocks}>
                  Refresh
                </Button>
              </Grid.Column>

              {!isDesktop &&
                <Grid.Column>
                  <Divider horizontal>No locks</Divider>
                </Grid.Column>
              }

              <Grid.Column>
                <Header icon>
                  <Icon name="lock" />
                  Create a new lock
                </Header>
                <Button primary as={Link} to="/locks/create">
                  Create lock
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
      :
      <Container as="main" id="HomeView" style={{ paddingTop: '4em' }}>
        <>
          <Divider horizontal>Managed locks</Divider>
          <GenerateLocks locks={locksState.managedLocks} />

          <Divider horizontal>Viewable locks</Divider>
          <GenerateLocks locks={locksState.viewableLocks} />
        </>
      </Container>
  )
}

const GenerateLocks = ({ locks }: { locks: any[] }) => {
  return (
    <Card.Group stackable itemsPerRow="3">
      {locks?.length > 0 && locks?.map((lock: any) =>
        <CodeCard pid={lock.pid} code={lock.code} name={lock.name} key={`CODE_CARD:${lock.pid}`} />
      )}
    </Card.Group>
  )
}

export default HomeView
