import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Redux store
import { recoverLocal } from '../../../store/actions/authActions'
import { updateRecoverFormFields } from '../../../store/actions/uiActions'
import { RootReducerState } from '../../../store/reducers'
// Components
import { Button, Form, Grid, Message, Header } from 'semantic-ui-react'
import PasswordFormField from '../../../components/PasswordFormField'
import PasswordStrength from '../../../components/PasswordStrength'
import CcLogo from '../../../components/CcLogo'

// Styles
import './RecoverView.scss'

function RecoverView() {
  const dispatch = useDispatch()
  const [params, setParams] = useSearchParams() // eslint-disable-line
  const recoverToken = params.get('recover-token');

  const [fieldsFilled, setFieldsFilled] = useState<boolean>(false)

  const formError = useSelector((state: RootReducerState) => state.ui.authentication.recover.error) // eslint-disable-line
  const formErrorMessage = useSelector((state: RootReducerState) => state.ui.authentication.recover.error.message)
  const formIsLoading = useSelector((state: RootReducerState) => state.ui.authentication.recover.isLoading)
  const formPassword = useSelector((state: RootReducerState) => state.ui.authentication.recover.fields.password)

  const onSubmitHandler = () => {
    if (fieldsFilled && recoverToken) {
      dispatch(recoverLocal({ recoverToken: recoverToken, password: formPassword }))
    }
  }

  const handleUpdateField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    dispatch(updateRecoverFormFields({ [name]: value }))
  }

  useEffect(() => {
    setFieldsFilled(!!formPassword && !!recoverToken)
  }, [formPassword, recoverToken])

  return (
    <main id="RecoverView">
      <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450, width: '100vw' }}>
          <CcLogo className="conlan-logo" />
          <Header as="h2" color="blue" textAlign="center">
            Choose a new password
          </Header>
          <Form size="large" className="top attached segment"
            loading={formIsLoading}
            error={formErrorMessage != null}>
            {/* TODO: Add recover-token input */}
            <PasswordFormField
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              value={formPassword}
              onChange={handleUpdateField}
              name="password"
            >
              {formPassword.length > 2 ? <PasswordStrength password={formPassword} /> : ''}
            </PasswordFormField>

            <Button primary fluid size="large" onClick={onSubmitHandler} disabled={!fieldsFilled}>
              Change password
            </Button>

            {String(formError.statusCode) === '404' ?
              <Message error>
                <Message.Header>Error, reset token has expired</Message.Header>
                <strong>
                  <Link to="/reset">Click here </Link>
                </strong>
                to request a new one
              </Message>
              :
              <Message error header="Error" content={typeof formErrorMessage === "string" && formErrorMessage}
              />
            }
            {recoverToken == null && formErrorMessage == null ? <Message negative header="Error" content="Reset token is missing" /> : null}
          </Form>
        </Grid.Column>
      </Grid>
    </main>
  )
}



export default RecoverView
